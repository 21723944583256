@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {

  glx-sign-in {

    section {
      background-color:  mat.get-theme-color($theme, surface-container-lowest);
      border-bottom-color: mat.get-theme-color($theme, primary);
    }

    .subtitle {
      color: mat.get-theme-color($theme, primary);
    }
      .mat-divider {
        border-top-color: mat.get-theme-color($theme, primary);
      }
    a {
      color: mat.get-theme-color($theme, primary);
    }
  }
}

@mixin typography($theme) {

  glx-sign-in {
    section{
      h1 {
        font-size: mat.get-theme-typography($theme, display-medium, font-size);
        font-weight: mat.get-theme-typography($theme, headline-medium, font-weight);;
      }
    }
    footer {
      text-align: center;
      font-style: italic;
    }

    .forgotten-password-link {
      font: mat.get-theme-typography($theme, label-small, font);
    }

    .subtitle {
      text-align: end;

      h2 {
        font: mat.get-theme-typography($theme, title-large, font);
        font-style: italic;
      }
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}