@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  glx-themes{
    
    .mat-tree, .router-drawer{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
       } @else {
        background-color:  mat.get-theme-color($theme, surface-container-lowest);
  
       }
    }
    .mat-drawer-side.mat-drawer-end{

      border-left-color: mat.get-theme-color($theme, outline-variant);
    }
  }
  glx-theme-detail {
    header{
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
    }
    h2 {
      color: mat.get-theme-color($theme, primary);
    }
  }

  glx-theme-translations-panel {
    .mat-expansion-panel {
      color: mat.get-theme-color($theme, outline-variant);
      .mat-caption {
        color: mat.get-theme-color($theme, primary);
        opacity: 0.82;
      }
      
      a {
        color: mat.get-theme-color($theme, primary);
      }
    }

    .mat-toolbar:last-child {
      background: none;
    }
  }
}

@mixin typography($theme) {

  glx-theme-detail {
    header .title-group {
      .mat-headline-6 {
        font: mat.get-theme-typography($theme, headline-small, font);
      }

      .subtitle {
        font: mat.get-theme-typography($theme, body-large, font);
      }
    }
    h2{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
    .mat-mdc-list-base .mat-mdc-list-item.mat-2-line h3 {
      font: mat.get-theme-typography($theme, label-medium, font);
    }
  }
  glx-provider-theme-association{
    .provider-label{
      font: mat.get-theme-typography($theme, label-large, font);
    }
    .provider-label-small{
      font: mat.get-theme-typography($theme, label-medium, font);
    }
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
