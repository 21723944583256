@use "@angular/material" as mat;
@use "sass:map";

@mixin color($theme) {
  $is-dark: mat.get-theme-type($theme) == dark;
  glx-status {
    background: mat.get-theme-color($theme, inverse-on-surface);;

    &.status-inactive,
    &.status-deleted-contract,
    &.status-deleted,
    &.status-deleted_contract {
      @if $is-dark {
        color: mat.get-theme-color($theme, on-error);
        background: mat.get-theme-color($theme, error);
      } @else {
        color: mat.get-theme-color($theme, on-error-container);
        background: mat.get-theme-color($theme, error-container);
      }
    }

    &.status-active {
      @if $is-dark {
        color: mat.get-theme-color($theme, primary-container);
        background: mat.get-theme-color($theme, on-primary-container);
      } @else {
        color: mat.get-theme-color($theme, primary);
        background:  rgba(mat.get-theme-color($theme, primary), 0.24);
      }
    }
  }
}

@mixin typography($theme) {

  glx-status {
    font: mat.get-theme-typography($theme, label-medium, font);
    line-height: 2em;
    text-transform: uppercase;
    letter-spacing: 0.09em;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
