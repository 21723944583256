@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}

@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  glx-import-audio-product-detail{
    h2, h3{
      color: mat.get-theme-color($theme, primary);
    }
  }
  glx-import-audio-products {
    .mat-drawer-container{
      border: 1px, solid, mat.get-theme-color($theme, outline-variant);
    }
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row, .mat-mdc-cell.mat-column-actions, form .mat-toolbar:last-child{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
      @if $type == dark {
       background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
       background-color:  mat.get-theme-color($theme, surface-container-lowest);
 
      }
  }
  }

  glx-import-audio-product-status {
    
    &.ingested {
        color: mat.get-theme-color($theme, primary);
        background:  rgba(mat.get-theme-color($theme, primary), 0.24);
    }
  }
}

@mixin typography($theme) {

  glx-import-audio-product-detail {
    .mat-mdc-list-base .mat-mdc-list-item {
      h3.mat-line {
        font: mat.get-theme-typography($theme, label-medium, font);
      }
    }
      h1,
      h2 {
        margin: 0;
      }

      h1 {
        font: mat.get-theme-typography($theme, headline-small, font);
      }
    
    
    h2, h3 {
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
  }

  glx-import-audio-products {
    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }

  glx-import-audio-product-status {
    font: mat.get-theme-typography($theme, label-medium, font);
    line-height: 2em;
    text-transform: uppercase;
    letter-spacing: 0.09em;
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}