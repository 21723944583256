@use "@angular/material" as mat;
@use "sass:map";

@mixin color($theme) {
  .mat-expansion-panel{
    color: mat.get-theme-color($theme, on-surface);

  }
  .mat-mdc-card{
    background-color: mat.get-theme-color($theme, surface-container-lowest);
  }
}

  @mixin theme($theme) {
    @if mat.theme-has($theme, color) {
      @include color($theme);
    }

  }
  