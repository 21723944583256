@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}

@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  glx-artists {
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row, .mat-mdc-cell.mat-column-actions, .mat-toolbar{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
      @if $type == dark {
       background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
       background-color:  mat.get-theme-color($theme, surface-container-lowest);
 
      }
  }
  }
 
  glx-artist-detail, glx-artist-editor {
    .artist-status {
      color: mat.get-theme-color($theme, outline-variant);

      &.master {
        color: mat.get-theme-color($theme, primary);
      }
    }
    .mat-expansion-panel{
      color: mat.get-theme-color($theme, outline-variant);
      
      .mat-body-2, .files, .mat-subtitle-2{
        color: mat.get-theme-color($theme, on-surface-variant);
      }
      h3 {
        color: mat.get-theme-color($theme, primary);
      }
    }
    .artist-photo,
    .artist-icon {
      color: rgba(mat.get-theme-color($theme, outline-variant), 0.36);
    }

    h3 {
      color: mat.get-theme-color($theme, primary);
    }

    // .mat-accordion .mat-expansion-panel {
    //   border-color: mat.get-theme-color($theme, outline-variant);
    // }
  }

  glx-artist-editor {
    p.mat-caption {
      color: mat.get-theme-color($theme, outline-variant);
    }

    .mat-toolbar:last-child {
      background: none;
    }
  }

  glx-artists-table{
    .check_circle{
      
        @if $type == dark {
          color: mat.get-theme-color($theme, on-primary-container);
        } @else {
          color: mat.get-theme-color($theme, primary);
        }
    }
    .info{
    color: #ffb300
    }
  }
  glx-artist-detail{
    .mat-mdc-icon-button .mat-icon{
      &.master {
        @if $type == dark {
          color: mat.get-theme-color($theme, on-primary-container);
        } @else {
          color: mat.get-theme-color($theme, primary);
        }
      }
    }
  }
  glx-biography-validation-status{

    &.rejected {
        color: mat.get-theme-color($theme, error);
    }
    &.toValidate {
   color: #ffb300
    }
    &.validated {
      @if $type == dark {
        color: mat.get-theme-color($theme, on-primary-container);
      } @else {
        color: mat.get-theme-color($theme, primary);
      }
    }
  }
}

@mixin typography($theme) {
  
  h1{
    font: mat.get-theme-typography($theme, headline-small, font);

  }
  h3{
    font: mat.get-theme-typography($theme, title-large, font);
    font-size: mat.get-theme-typography($theme, title-medium, font-size);
  }
  glx-artist-detail,
  glx-artist-aliases-linking {
    .mat-mdc-list-base .mat-mdc-list-item {
      h3.mat-line {
        font: mat.get-theme-typography($theme, label-medium, font);
      }
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}