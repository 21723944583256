:root,
:host {
    --sys-background: #f7f9ff;
    --sys-on-background: #171d19;
    --sys-surface: #f5fbf5;
    --sys-surface-dim: #d6dbd5;
    --sys-surface-bright: #f5fbf5;
    --sys-surface-container-lowest: #ffffff;
    --sys-surface-container-low: #f7f9ff;
    --sys-surface-container: #eaefe9;
    --sys-surface-container-high: #e4eae3;
    --sys-surface-container-highest: #dee4de;
    --sys-on-surface: #171d19;
    --sys-surface-variant: #dbe5dd;
    --sys-on-surface-variant: #404943;
    --sys-inverse-surface: #2c322e;
    --sys-inverse-on-surface: #edf2ec;
    --sys-outline: #707973;
    --sys-outline-variant: #bfc9c2;
    --sys-shadow: #000000;
    --sys-scrim: #000000;
    --sys-surface-tint: #1e6a4f;
    --sys-primary: #1e6a4f;
    --sys-on-primary: #ffffff;
    --sys-primary-container: #a8f2cf;
    --sys-on-primary-container: #002115;
    --sys-inverse-primary: #8cd5b3;
    --sys-secondary: #1d6b50;
    --sys-on-secondary: #ffffff;
    --sys-secondary-container: #a7f2d0;
    --sys-on-secondary-container: #002115;
    --sys-tertiary: #126682;
    --sys-on-tertiary: #ffffff;
    --sys-tertiary-container: #bee9ff;
    --sys-on-tertiary-container: #102a43;
    --sys-error: #904a43;
    --sys-on-error: #ffffff;
    --sys-error-container: #ffdad5;
    --sys-on-error-container: #3b0907;
    --sys-neutral: #757b76;
    --sys-neutral-variant: #707973;
    --sys-primary-fixed: #a8f2cf;
    --sys-primary-fixed-dim: #8cd5b3;
    --sys-on-primary-fixed: #002115;
    --sys-on-primary-fixed-variant: #005139;
    --sys-secondary-fixed: #a7f2d0;
    --sys-secondary-fixed-dim: #8cd5b4;
    --sys-on-secondary-fixed: #002115;
    --sys-on-secondary-fixed-variant: #00513a;
    --sys-tertiary-fixed: #bee9ff;
    --sys-tertiary-fixed-dim: #8bd0f0;
    --sys-on-tertiary-fixed: #001f2a;
    --sys-on-tertiary-fixed-variant: #004d64;
    --sys-inverse-secondary: #8cd5b4;
    --sys-inverse-tertiary: #8bd0f0;
    --sys-inverse-error: #ffb4ab;
    --sys-error-fixed: #ffdad5;
    --sys-error-fixed-dim: #ffb4ab;
    --sys-on-error-fixed: #3b0907;
    --sys-on-error-fixed-variant: #73342d;
}