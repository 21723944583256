@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);


  glx-rules {
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row, .mat-mdc-cell.mat-column-actions{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
      @if $type == dark {
       background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
       background-color:  mat.get-theme-color($theme, surface-container-lowest);
 
      }
  }
  }

  glx-rule-detail {
    @if($type == dark){
      background-color: mat.get-theme-color($theme, tertiary, 10);
    } @else {
      background-color: mat.get-theme-color($theme, surface-container-lowest);
    }
    h3 {
      color: mat.get-theme-color($theme, primary);
    }
  }

  glx-rule-editor {
    .mat-mdc-dialog-content h2 {
      color: mat.get-theme-color($theme, primary);
    }

    .mat-accordion + .mat-toolbar {
      background: none;
    }
  }

  glx-rule-contents-filter-infos {
    h3 {
      color: mat.get-theme-color($theme, primary);
    }
  }
}

@mixin typography($theme) {

  glx-rule-detail, glx-rule-editor {
    header .title-group {
      .title {
        font: mat.get-theme-typography($theme, headline-small, font);
      }
     
      .subtitle {
        font: mat.get-theme-typography($theme, body-large, font);
      }
     
    }
    h2{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
    .mat-mdc-list-base {
      .mat-mdc-list-item.mat-2-line {
        font: mat.get-theme-typography($theme, label-medium, font);
      }
      .mat-mdc-list-item .fi.fis {
        font-size: 1.5em;
      }
    }
  }

  glx-rule-editor {
    .mat-mdc-form-field .fi.fis {
      font-size: 1.5em;
    }
  }

  glx-rule-contents-filter-infos {
    h3{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
    .mat-mdc-list-base .mat-mdc-list-item.mat-2-line {
      h4 {
        font: mat.get-theme-typography($theme, label-medium, font);
      }
      p {
        font: mat.get-theme-typography($theme, body-medium, font);
      }
    }
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}