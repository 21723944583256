@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);

  glx-albums {
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
      @if $type == dark {
       background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
       background-color:  mat.get-theme-color($theme, surface-container-lowest);
 
      }
     }
  }
  glx-albums-filters{
    .mat-toolbar{
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    }

  glx-albums-edit,
  glx-albums-create-editor{
    h2 {
      color: mat.get-theme-color($theme, primary);
    }
  }
  glx-albums-details,
  glx-albums-songs-detail {
    @if $type == dark {
      background-color: mat.get-theme-color($theme, tertiary, 10);
     } @else {
      background-color:  mat.get-theme-color($theme, surface-container-lowest);

     }
     header {
      background-color: mat.get-theme-color($theme, surface-container-lowest);
        border-bottom-color: mat.get-theme-color($theme, outline-variant);
        border-left-color: mat.get-theme-color($theme, outline-variant);

      }
     border-left: 1px solid mat.get-theme-color($theme, outline-variant);

    .mat-subtitle-1 {
      color: mat.get-theme-color($theme, primary);
    }
    h2.mat-subtitle-1, h3{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
  }

  }

@mixin typography($theme) {

  glx-albums{
    .columns-drawer{
      h2 {
        font: mat.get-theme-typography($theme, title-large, font);
      }
    }
  }
  glx-albums-details,
  glx-albums-edit,
  glx-albums-create-editor {
    h2 {
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
    header{
      .title-group{
        h1.title{
          font: mat.get-theme-typography($theme, headline-small, font);
        }
      }
    }
   
  }

}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}