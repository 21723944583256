@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);


  glx-providers {
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row, .mat-mdc-cell.mat-column-actions, .mat-toolbar{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
      @if $type == dark {
       background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
       background-color:  mat.get-theme-color($theme, surface-container-lowest);
 
      }
  }
  }

  glx-provider-detail {
    header{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
       } @else {
        background-color:  mat.get-theme-color($theme, surface-container-lowest);
  
       }
    }
    h2 {
      color: mat.get-theme-color($theme, primary);
    }
  }

  glx-provider-filters {
    h2 {
      color: mat.get-theme-color($theme, primary);
    }
  }

  glx-provider-editor {
    h2 {
      color: mat.get-theme-color($theme, primary);
    }
  }

  glx-provider-licences-panel {
    .mat-expansion-panel{
      color: mat.get-theme-color($theme, outline-variant);
      .mat-expansion-panel-content{
        color: mat.get-theme-color($theme, on-surface-variant);
      }
    }
     
    
    .mat-toolbar:last-child {
      background: none;
    }
  }
}

@mixin typography($theme) {
 
  glx-provider-detail {
    header .title-group {
      .mat-headline-6 {
        font: mat.get-theme-typography($theme, headline-small, font);
      }

      .subtitle {
        font: mat.get-theme-typography($theme, body-large, font);
      }
    }
    h2{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
    .mat-mdc-list-base .mat-mdc-list-item.mat-2-line h3 {
      font: mat.get-theme-typography($theme, label-medium, font);
    }
  }

  glx-provider-licences-panel {
    .mat-expansion-panel{
     h3{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
     }
    }
     
    
   
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}