@use "@angular/material" as mat;
@use "sass:map";



@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  .mat-toolbar.logo-toolbar{
      background-color: mat.get-theme-color($theme, tertiary, 60);
      color: mat.get-theme-color($theme, on-tertiary-container);
    
  }

    
  

  glx-main-menu {
    .mat-mdc-list-base {
      background-color: mat.get-theme-color($theme, on-tertiary-container);
      color: mat.get-theme-color($theme, on-tertiary);
    }
    .mdc-list-group__subheader{
      color: mat.get-theme-color($theme, on-tertiary);
    }
    .mdc-list-item__primary-text{
      color: mat.get-theme-color($theme, on-tertiary);

    }
 
    .mdc-list-item--with-leading-icon .mdc-list-item__start{
      color: mat.get-theme-color($theme, on-tertiary);
    }
    .mat-mdc-list-base .mdc-list-item {    
      &:hover{
        .mdc-list-item__primary-text{
          color: mat.get-theme-color($theme, on-tertiary-container
          );    
        }
        background-color: mat.get-theme-color($theme, tertiary-container);
        .mat-icon {
          color: mat.get-theme-color($theme, on-tertiary-container
          );
        }
      }
      &.active {
        background-color: mat.get-theme-color($theme, tertiary-container);
        .mdc-list-item__primary-text{
          color: mat.get-theme-color($theme, on-tertiary-container
          );    
        }
        .mat-icon {
          color: mat.get-theme-color($theme, on-tertiary-container
          );
        }
      }
    }
  }

}
@mixin typography($theme) {
  glx-dashboard{
    h1 {
      font: mat.get-theme-typography($theme, body-large, font);
      font-size: mat.get-theme-typography($theme, display-medium, font-size);
    }
    h2 {
      font: mat.get-theme-typography($theme, title-large, font);
    }
  }
  .mat-toolbar{
    .site-name{
      font: mat.get-theme-typography($theme, headline-small, font);
    }
  
}
glx-main-menu{
  .mdc-list-group__subheader{
    font: mat.get-theme-typography($theme, title-large, font);
    font-size: mat.get-theme-typography($theme, title-medium, font-size);
  }
}
  
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}