@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {

  .success-snack-bar {
    color: mat.get-theme-color($theme, on-primary);
    background: mat.get-theme-color($theme, primary);

    .mat-mdc-snack-bar-action {
      color: mat.get-theme-color($theme, on-primary-container);
    }
  }

  .error-snack-bar {
    color: mat.get-theme-color($theme, on-error);
    background: mat.get-theme-color($theme, error);

    .mat-mdc-snack-bar-action {
      color: mat.get-theme-color($theme, error-container);
    }
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
