@use '@angular/material' as mat;
@use "sass:map";


@mixin color($theme) {
$type: mat.get-theme-type($theme);
  glx-contents{
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row, .mat-toolbar{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
     @if $type == dark {
      background-color: mat.get-theme-color($theme, tertiary, 10);
     } @else {
      background-color:  mat.get-theme-color($theme, surface-container-lowest);

     }
    }
    .mat-drawer.filters-selection-drawer {
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: mat.get-theme-color($theme, surface-container-lowest);
      }
    }
  }
  glx-content-assets-import-dialog{
    .mat-mdc-table{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: mat.get-theme-color($theme, surface-container-lowest);
      }
    }
  }
  glx-content-translations-panel, glx-content-deliveries-panel, glx-content-rights-panel{
    .mat-expansion-panel{
      color: mat.get-theme-color($theme, outline-variant);
      .mat-body-2, .files{
        color: mat.get-theme-color($theme, on-surface-variant);
      }
    }
   

  }
  glx-contents-detail,
  glx-content-detail-dialog {
    .mat-mdc-dialog-content, .mat-mdc-dialog-actions{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: mat.get-theme-color($theme, surface-container-lowest);
      }
    }
    header {
      background-color: mat.get-theme-color($theme, surface-container-lowest);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
    }

    .mat-subtitle-1 {
      color: mat.get-theme-color($theme, primary);
    }
  }

  glx-contents-filters{
    .mat-subtitle-1{
      color: mat.get-theme-color($theme, primary);
    }
    .example-accordion-item-header{
      color: mat.get-theme-color($theme, primary);
    }
    .mat-toolbar{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
       } @else {
        background-color:  mat.get-theme-color($theme, surface-container-lowest);
  
       }
    }
    
  }

  glx-contents-editor, glx-many-contents-editor {
    
    h2 {
      color: mat.get-theme-color($theme, primary);
    }
  }

  glx-content-translations-panel, glx-content-rights-panel {
    .mat-expansion-panel {
      h3 {
        color: mat.get-theme-color($theme, primary);
      }

      a {
        color: mat.get-theme-color($theme, primary);
      }
    }
    .mat-toolbar:last-child {
      background: none;
    }
  }

  glx-content-deliveries-panel,
  glx-wrapper-status-panel {
    .mat-expansion-panel {
      .url {
        color: mat.get-theme-color($theme, primary);
      }
    }

    .mat-toolbar:last-child {
      background: none;
    }
  }

  glx-content-rights-panel {
    .mat-toolbar:last-child {
      background: none;
    }
  }
  glx-content-file-list,
  glx-content-assets,
  glx-content-assets-dialog {
    .mat-mdc-list-base .mat-mdc-list-item {
      .mat-icon.mat-mdc-list-item-avatar {
        background: mat.get-theme-color($theme, primary);
        color: mat.get-theme-color($theme, on-primary);
      }
    }
  }
}
  


@mixin typography($theme) {
  glx-contents-filters, glx-content-right-editor, glx-contents-preferences-selection{
    h2 {
      font: mat.get-theme-typography($theme, title-large, font);
    }
    h3{
      font: mat.get-theme-typography($theme, title-medium, font);
    }
  }
  

  glx-contents-editor, glx-many-contents-editor, glx-contents-detail, glx-content-infos, glx-audio-content-infos, glx-video-content-infos, glx-application-content-infos,  glx-content-detail-dialog{
    
    h2 {
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
  }
  glx-content-translations-panel, glx-content-rights-panel{
    .mat-expansion-panel{
      .mat-subtitle-2{
        font: mat.get-theme-typography($theme, title-medium, font);
      }
      .mat-subtitle-1{
        font: mat.get-theme-typography($theme, title-medium, font);
      }
    }
  }


  glx-contents-detail, glx-content-rubric-use, glx-content-detail-dialog {
    h3{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
    h1{
      font: mat.get-theme-typography($theme, headline-small, font);
    }

  }

  
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}