@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  glx-campaigns {
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row, .mat-mdc-cell.mat-column-actions, .mat-toolbar{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
      @if $type == dark {
       background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
       background-color:  mat.get-theme-color($theme, surface-container-lowest);
 
      }
  }
  }
  glx-campaign-contents-filter-editor{
    .mat-toolbar{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
        
       } @else {
        background-color:  mat.get-theme-color($theme, surface-container-lowest);
       }
    }
  }
  glx-campaign{
    .campaign-details-drawer{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color:  mat.get-theme-color($theme, surface-container-lowest);
      }
      border-right-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-subtitle-2 {
      color: mat.get-theme-color($theme, primary);
    }
  }
  glx-rubric-breadcrumb{
    @if $type == dark {
      background-color: mat.get-theme-color($theme, tertiary, 10);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);;
     } @else {
      background-color:  mat.get-theme-color($theme, surface-container-lowest);
     }
  }
  glx-price-credit-table, glx-licence-credit-table{
    .mat-mdc-table{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
        
       } @else {
        background-color:  mat.get-theme-color($theme, surface-container-lowest);
       }
    }
  }
  glx-campaign-toolbar{
    .mat-toolbar{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
        border-bottom-color: mat.get-theme-color($theme, outline-variant);
       } @else {
        background-color:  mat.get-theme-color($theme, surface-container-lowest);
       }
    }
  }
  glx-campaign-contents-filter-infos, glx-campaign-contents-filter-editor {
    .mat-expansion-panel{
      color: mat.get-theme-color($theme, outline-variant);
    }
  
}
glx-campaign-details {
  header{
    @if $type == dark {
      background-color: mat.get-theme-color($theme, tertiary, 10);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
     } @else {
      background-color:  mat.get-theme-color($theme, surface-container-lowest);
     }
  }
  }
}
@mixin typography($theme) {


  glx-campaign {
    .mat-subtitle-1, .mat-subtitle-2, .mat-expansion-panel-header-title {
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
  }
  glx-campaign-contents-filter-editor{
    h2{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
  }


  glx-campaign-contents-filter-infos {
    
      .mat-caption {
        font: mat.get-theme-typography($theme, label-medium, font);
      }
    
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
