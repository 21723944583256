@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);

  glx-search-engine{
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
     @if $type == dark {
      background-color: mat.get-theme-color($theme, tertiary, 10);
     } @else {
      background-color:  mat.get-theme-color($theme, surface-container-lowest);

     }
    }
    .mat-drawer.filters-drawer {
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: mat.get-theme-color($theme, surface-container-lowest);
      }
    }
  }

}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

}