@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  $rubric-background: mat.get-theme-color($theme, surface-container-lowest);
  $rubric-border: mat.get-theme-color($theme, outline-variant);
  glx-rubric {
    
    .rubric-infos, .rubric-side-drawer {
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: $rubric-background;
      }
      border-right-color:  $rubric-border;
      header {
        background-color: $rubric-background;
        border-bottom-color: $rubric-border;
      }
    }
  }

glx-rubric-container{
  .mat-divider.mat-divider-vertical{
    border-right-color: mat.get-theme-color($theme, outline-variant);
  }
}
  glx-rubric-details {
    header{
      background-color: $rubric-background;
      border-bottom-color: $rubric-border
    }
    background-color: $rubric-background;
  }
  glx-rubric-translation-panel{
    .mat-toolbar:last-child{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: $rubric-background;
      }
    }
    .mat-expansion-panel{
      color: mat.get-theme-color($theme, outline-variant);
      .mat-action-row{
        border-top-color: mat.get-theme-color($theme, outline-variant);
      }
      .mat-body-2, .files, .mat-subtitle-2{
        color: mat.get-theme-color($theme, on-surface-variant);
      }
      h3 {
        color: mat.get-theme-color($theme, primary);
      }
    }
  }
  glx-add-contents-to-rubric{
    .filters-drawer, .mat-divider.mat-divider-vertical, header, mat-toolbar{
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .filters-drawer{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: $rubric-background;
      }
    }
   
  }
  glx-rubric-contents {
    > header, .mat-mdc-paginator {
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: $rubric-background;
      }
    }
  }

  glx-rubric-preview-image {
    label {
      border-color: $rubric-border;
    }
  }

  glx-rubric-tree {
    $dark-background: mat.get-theme-color($theme, tertiary, 10);
    $light-background:  mat.get-theme-color($theme, surface-container-lowest);
      @if $type == dark {
        background-color: $dark-background;
      } @else {
        background-color: $light-background;
      }
      @include mat.list-overrides((
        active-indicator-color: transparent,

      ));
    .mat-toolbar, .mat-tree {
      @if $type == dark {
        background-color: $dark-background;
      } @else {
        background-color: $light-background;
      }
    }

    .mat-drawer-content header {
      border-bottom-color: $rubric-border
    }

  }

  glx-rubric-content-cards{
    .mat-mdc-card{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: $rubric-background;
      }
    }
    .disabled-card  {
          background-color: rgba(0, 0, 0, 0.12);
        }
  }
  glx-rubric-content-list{
    .mat-mdc-list-base{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: $rubric-background;
      }
    }
    .mat-list-multi-container{
      @if $type == dark {
        background-color: mat.get-theme-color($theme, tertiary, 10);
      } @else {
        background-color: $rubric-background;
      }
    }
    
  }
  
}

@mixin typography($theme) {
 

  glx-rubric {
    header .subtitle {
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  glx-rubric-details {
h2.mat-subtitle-1{
  font: mat.get-theme-typography($theme, title-large, font);
  font-size: mat.get-theme-typography($theme, title-medium, font-size);

}
  }
  glx-rubric-previews{
    .caption{
      font: mat.get-theme-typography($theme, label-small, font);
    }
  }
  glx-rubric-translation-panel{
    .mat-body-2{
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }
  glx-rubric-contents{
    .mat-body-1{
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }

  glx-rubric-content-cards,
  glx-rubric-content-list, glx-carts-detail {
    .from-rule-label {
      font: mat.get-theme-typography($theme, label-small, font);
      line-height: 2em;
      text-transform: uppercase;
      letter-spacing: 0.09em;
    }
    .mat-mdc-card-title {
      font: mat.get-theme-typography($theme, title-large, font);
    font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
    .mat-mdc-card-subtitle{
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }
  glx-add-contents-to-rubric{
    .mat-body-1{
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
