@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  glx-series {
    .mat-mdc-cell, .mat-mdc-header-cell, .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-action-row, .mat-toolbar{
      border-left-color: mat.get-theme-color($theme, outline-variant);
      border-right-color: mat.get-theme-color($theme, outline-variant);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
      border-top-color: mat.get-theme-color($theme, outline-variant);
    }
    .mat-drawer-side, .mat-drawer-side.mat-drawer-end, .mat-mdc-table, .mat-mdc-paginator, .mat-toolbar{
     @if $type == dark {
      background-color: mat.get-theme-color($theme, tertiary, 10);
     } @else {
      background-color:  mat.get-theme-color($theme, surface-container-lowest);

     }
    }
  }

  glx-serie-detail {
    border-left: 1px solid mat.get-theme-color($theme, outline-variant);
    h2 {
      color: mat.get-theme-color($theme, primary);
    }

    header {
      background-color: mat.get-theme-color($theme, surface-container-lowest);
      border-bottom-color: mat.get-theme-color($theme, outline-variant);

    }

    .mat-drawer-content {
      header {
        background: transparent;
        border-bottom: none;
      }
    }
  }
}

@mixin typography($theme) {
  .filters-drawer{
    h2 {
      font: mat.get-theme-typography($theme, title-large, font);
    }
  }
  glx-content-filters-form{
    h3{
      font: mat.get-theme-typography($theme, title-medium, font);
    }
  }
  glx-serie-detail,
  glx-serie-editor {
    header .title-group {
      .mat-headline-6 {
        font: mat.get-theme-typography($theme, headline-small, font);
      }

      
    }
    h2.mat-subtitle-1, h3{
      font: mat.get-theme-typography($theme, title-large, font);
      font-size: mat.get-theme-typography($theme, title-medium, font-size);
    }
   
  }

}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}