@use "@angular/material" as mat;
@use "sass:map";
@mixin color($theme) {
    $type: mat.get-theme-type($theme);
    glx-custom-table{
        .mat-mdc-table{
          @if $type == dark {
            background-color: mat.get-theme-color($theme, tertiary, 10);
            
           } @else {
            background-color:  mat.get-theme-color($theme, surface-container-lowest);
           }
        }
      }
}


  @mixin theme($theme) {
    @if mat.theme-has($theme, color) {
      @include color($theme);
    }
  }