@use "@angular/material" as mat;
@use "sass:map";


@mixin color($theme) {


  glx-licences-tree,
  glx-campaign-tree, .search-field {
    background-color: mat.get-theme-color($theme, surface-container-lowest);
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

}
