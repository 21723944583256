@use "@angular/material" as mat;
@use "app/core/theming" as glx;
@use "sass:map";
@use 'app/core/theming/flex-layout';
@use 'app/core/theming/themingm3-theme' as m3-theme;


@include mat.core();
:root {
  @include mat.all-component-themes(m3-theme.$light-theme);
  @include mat.system-level-colors(m3-theme.$light-theme);
  @include mat.system-level-typography(m3-theme.$light-theme); 
  .mat-mdc-dialog-surface {
    background-color: mat.get-theme-color(m3-theme.$light-theme, surface-container-lowest);
  }
  .mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    padding: 20px 24px;
  }
  .mat-expansion-panel{
    background-color: mat.get-theme-color(m3-theme.$light-theme, surface-container-lowest);
  }
  .warning{
    --mdc-filled-button-container-color: #f44336;
    --mdc-filled-button-label-text-color: white;
  }
  .error{
    color: #f44336;
  }
}
html, body{ 
  height: 100%;
  @include glx.home-theme(m3-theme.$light-theme);
  @include glx.auth-theme(m3-theme.$light-theme);
  @include glx.content-theme(m3-theme.$light-theme);
  @include glx.licences-select-theme(m3-theme.$light-theme);
  @include glx.status-theme(m3-theme.$light-theme);
  @include glx.serie-theme(m3-theme.$light-theme);
  @include glx.albums-theme(m3-theme.$light-theme);
  @include glx.campaign-theme(m3-theme.$light-theme);
  @include glx.rubric-theme(m3-theme.$light-theme);
  @include glx.search-engine-theme(m3-theme.$light-theme);
  @include glx.rule-theme(m3-theme.$light-theme);
  @include glx.provider-theme(m3-theme.$light-theme);
  @include glx.theme-theme(m3-theme.$light-theme);
  @include glx.audio-content-theme(m3-theme.$light-theme);
  @include glx.artist-theme(m3-theme.$light-theme);
  @include glx.cart-theme(m3-theme.$light-theme);
  @include glx.snack-bar-theme(m3-theme.$light-theme);
  @include glx.documentation-theme(m3-theme.$light-theme);
  @include glx.custom-table-theme(m3-theme.$light-theme);
  background: var(--sys-background);
  color: mat.get-theme-color(m3-theme.$dark-theme, tertiary, 10);
    .dark{
      background-color: var(--sys-on-tertiary-container);
      color: var(--sys-surface-container-highest);
      .warning{
        --mdc-filled-button-container-color: #e53935;
        --mdc-filled-button-label-text-color: white;
      }
      div.mat-mdc-select-panel{
        background-color: mat.get-theme-color(m3-theme.$dark-theme, background);
      }
      .mat-mdc-menu-panel{
        background-color: mat.get-theme-color(m3-theme.$dark-theme, tertiary, 10);
      }
      .tertiary-button {
        @include mat.button-color(m3-theme.$dark-theme, $color-variant: tertiary);
      }
      .mat-expansion-panel, .mat-drawer-container{
        background-color: mat.get-theme-color(m3-theme.$dark-theme, surface-container-lowest);
      }
      .mat-mdc-dialog-surface {
        background-color: mat.get-theme-color(m3-theme.$dark-theme, background);
      }
      .mat-toolbar.galaxy-toolbar{
        background-color: mat.get-theme-color(m3-theme.$dark-theme, tertiary, 10);
      }
      @include mat.all-component-colors(m3-theme.$dark-theme);
      @include glx.content-color(m3-theme.$dark-theme);
      @include glx.auth-color(m3-theme.$dark-theme);
      @include glx.licences-select-color(m3-theme.$dark-theme);
      @include glx.status-color(m3-theme.$dark-theme);
      @include glx.serie-color(m3-theme.$dark-theme);
      @include glx.albums-color(m3-theme.$dark-theme);
      @include glx.campaign-color(m3-theme.$dark-theme);
      @include glx.rubric-color(m3-theme.$dark-theme);
      @include glx.search-engine-color(m3-theme.$dark-theme);
      @include glx.rule-color(m3-theme.$dark-theme);
      @include glx.provider-color(m3-theme.$dark-theme);
      @include glx.theme-color(m3-theme.$dark-theme);
      @include glx.audio-content-color(m3-theme.$dark-theme);
      @include glx.artist-color(m3-theme.$dark-theme);
      @include glx.cart-color(m3-theme.$dark-theme);
      @include glx.snack-bar-color(m3-theme.$dark-theme);
      @include glx.documentation-color(m3-theme.$dark-theme);
      @include glx.custom-table-color(m3-theme.$dark-theme);
  }
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
    .mat-divider{
      border-top-color: mat.get-theme-color(m3-theme.$light-theme, outline-variant);
  };
  div.mat-mdc-select-panel{
    background-color: mat.get-theme-color(m3-theme.$light-theme, background);
  }
  .mat-mdc-menu-panel{
    background-color: mat.get-theme-color(m3-theme.$light-theme, on-primary);
  }
  .mat-drawer-container{
    background-color: mat.get-theme-color(m3-theme.$light-theme, neutral, 99);
  }
  .mat-toolbar.galaxy-toolbar{
    background-color:  mat.get-theme-color(m3-theme.$light-theme, surface-container-lowest);
  }
}
.delete-button{
  background-color: mat.get-theme-color(m3-theme.$light-theme, error-container);
  color: mat.get-theme-color(m3-theme.$light-theme, on-error-container);
}
.separator {
  flex: 1;
}
glx-home .mat-sidenav.dark {
  background-color: mat.get-theme-color(m3-theme.$dark-theme, tertiary, 10);
}


.search-field {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;

  input {
    font: inherit;
    background: transparent;
    color: currentColor;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    box-sizing: content-box;
  }

  .mat-mdc-button-base {
    margin-right: -8px;
  }
}

.loading-shade {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mat-mdc-optgroup .mat-mdc-optgroup-label {
  .mat-divider {
    margin: 0 -24px;
  }

  .fi {
    margin-right: 8px;
    border-radius: 100%;
  }
}

.non-list-with-columns {
  column-count: 2;
  gap:40px;
  column-rule-style: solid;
  column-rule-width: 1px;
 

  .column-break {
    break-before: column;
  }
}

.mat-mdc-list-base {
  &.list-with-columns {
    column-count: 2;
    gap:40px;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-color: mat.get-theme-color(m3-theme.$light-theme, outline-variant);
    .mat-mdc-list-item {
      break-inside: avoid-column;
    }

    .column-break {
      break-before: column;
    }
  }
}

.mat-expansion-panel.mat-elevation-z0 {
  border: {
    width: 1px;
    style: solid;
  }

  &:not(.mat-expansion-panel-spacing) {
    &+& {
      border-top: none;
    }
  }
}

.cdk-drag {
  cursor: move;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0.34;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-mdc-form-field {
  margin: 0.25em 0;
}
.slimmer-input {
  @include mat.form-field-density(-5);
  width: 100px;
}
.fi.fis {
  $flag-icon-size: 1.5em;
  $flag-icon-spacing: 12px;

  .mat-mdc-list-item-avatar &,
  .mat-mdc-option &,
  .mat-mdc-form-field-suffix &,
  .mat-mdc-form-field-prefix & {
    border-radius: 50%;
    width: $flag-icon-size;
    height: $flag-icon-size;
    line-height: $flag-icon-size;
  }

  .mat-mdc-option &,
  .mat-mdc-form-field-prefix & {
    margin-right: $flag-icon-spacing;
  }

  .mat-mdc-form-field-suffix & {
    margin-left: $flag-icon-spacing;
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: none;
  
}

::-webkit-scrollbar-thumb {
  margin-top: 20px;
  background: mat.get-theme-color(m3-theme.$light-theme, tertiary);
  border-radius: 4px;
}
::-webkit-scrollbar-track{
  padding: 10px 0;
}


.need-permission-deny {
  pointer-events: none;
}

.mat-tree {
  box-shadow: none;
}

.dark-info {
  color: rgba(36, 59, 83, 0.87);
}
